import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

// Images
import VideoTitle from './../../assets/images/ajuda-como-pode.png'

export const BannerVideo = styled.div`
  width: 100%;
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: url(${VideoTitle}) no-repeat center center;
  background-color: #803d10;
  margin: 0 auto;
  background-size: contain;
  
  @media ${device.tablet} {
    height: 320px;
    background-size: contain;    
  }

  @media ${device.desktopXL} {
    height: 400px;
    background-size: contain;
  }
`
