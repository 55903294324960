import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from './../../pageQuery'

import { Section } from './style'

const PessoasPrimeiro = () => {
const data = usePageQuery()
return (
  <Section id='conta-com-a-gente' className='py-5'>
    <div className='container d-flex flex-wrap align-items-center justify-content-around px-5'>
      <div className='col-12 col-md mb-3 mb-md-0 mx-3' style={{ maxWidth: '348px' }}>
        <Img fluid={data.parceriaCoracoes.fluid} alt='Coracoes' />
      </div>
      <div className='col-12 col-md-7'>
        <h3 className='fs-40 text-center text-md-right'>Conta com a gente</h3>
        <div className='text-center text-md-right'>
          A jornada contra o coronavírus pode ser longa, mas quando ficamos unidos, mesmo que distantes, tudo fica
          mais fácil. <b>Conta com a gente, agora e sempre.</b>
        </div>
      </div>
    </div>
  </Section>
)
}

export default PessoasPrimeiro
