import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import Header from './sections/header/_header'
import PlayVideo from './sections/play-video/_play-video'
import NovasMedidas from './sections/novas-medidas/_novas-medidas'
import PessoasPrimeiro from './sections/pessoas-primeiro/_pessoas-primeiro'
import NaoCustaLembrar from './sections/nao-custa-lembrar/_nao-custa-lembrar'
import ConteComAGente from './sections/conta-com-a-gente/_conta-com-a-gente'
import Faq from './sections/faq/_index'

import pageContext from './pageContext.json'

import { Wrapper } from './style'

const Parceria = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Header />
        <PlayVideo />
        <NovasMedidas />
        <PessoasPrimeiro />
        <NaoCustaLembrar />
        <ConteComAGente />
        <Faq />
      </Layout>
    </Wrapper>
  )
}

export default Parceria
