
import React, { ReactNode, useState } from 'react'
import useWidth from 'src/hooks/window/useWidth'

type viewMoreProps = {
  classwrapper: string;
  height: number | string;
  children: ReactNode;
  color: string;
}

const WIDTH_MD = 768
function ViewMore ({ classwrapper, height, children, color }: viewMoreProps) {
  const windowWidth = useWidth(300)
  const [ hiddenHeight, setHiddenHeight ] = useState(`${height}px`)

  function showContent (evt: React.MouseEvent<HTMLAnchorElement>) {
    evt && evt.preventDefault()
    setHiddenHeight('100%')
  }

  const viewMoreButton = hiddenHeight !== '100%' &&
    (
      <div className={`col-12 rounded-2 ${color === 'gray' ? 'bg-gray' : 'bg-white'} p-2 mt-3 mb-4 border border-primary text-center d-md-none`}>
        <a
          href='#' className='text-orange--extra fs-14 lh-17 fw-700'
          onClick={showContent}
        >Mostrar todas
        </a>
      </div>
    )

  return (
    <>
      <div
        style={{ height: (windowWidth < WIDTH_MD) ? hiddenHeight : '' }}
        className={`${classwrapper} ${hiddenHeight !== '100%' ? 'overflow-hidden' : ''}`}
      >
        {children}
      </div>
      {viewMoreButton}
    </>
  )
}

ViewMore.defaultProps = {
  classwrapper: '',
  color: 'bg-white',
}

export default ViewMore
