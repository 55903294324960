import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from './../../pageQuery'

import { Section } from './style'

const PessoasPrimeiro = () => {
const data = usePageQuery()
return (
  <Section id='pessoas-em-primeiro-lugar' className='py-5'>
    <div className='container d-flex flex-column-reverse flex-md-row align-items-center justify-content-around px-5'>
      <div className='col-12 col-md-7'>
        <h3 className='fs-40 text-center text-md-left'>Pessoas em primeiro lugar</h3>
        <div className='text-center text-md-left'>
          Nossa responsabilidade com a sociedade vai muito além dos nossos correntistas. Em parceria com a MRV,
          Log e família Menin, <b>doamos 10 milhões de reais para a compra de respiradores no estado de Minas Gerais.</b>
        </div>
      </div>
      <div className='col-12 col-md mx-3 mb-5 mt-md-0' style={{ maxWidth: '268px' }}>
        <Img fluid={data.parceriaFamilia.fluid} alt='Familia' />
      </div>
    </div>
  </Section>
)
}

export default PessoasPrimeiro
