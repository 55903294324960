import React from 'react'
import Img from 'gatsby-image'

import { CautionIcon } from './style'
import usePageQuery from './../../pageQuery'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const NaoCustaLembrar = () => {
 const data = usePageQuery()
 const [ sendDatalayerEvent ] = useDataLayer()

 return (
   <section id='cuidados' className='my-3 py-5'>
     <div className='container'>
       <h3 className='fs-40 text-center'>Você já sabe, mas não custa lembrar</h3>
       <div className='text-center px-2' style={{ maxWidth: '780px', margin: '0 auto' }}>
         Todos têm seu papel nesse momento. Aqui vão algumas das ações que você pode tomar para que você e as pessoas
         ao seu redor fiquem mais seguros:
       </div>
       <div className='row mt-5'>
         <div className='col-12 col-md-6 col-lg-4 text-center mb-3'>
           <CautionIcon className='pt-3'>
             <Img fluid={data.evitarContatoFisico.fluid} alt='Evitar contato fisico' />
           </CautionIcon>
           <b>Evite o contato físico</b>
           <div className='mt-3'>
             Se puder, evite o contato físico nesse período de risco. Mas também cuide de sua saúde mental, e use
             a tecnologia a seu favor para manter o contato com família e amigos.
           </div>
         </div>
         <div className='col-12 col-md-6 col-lg-4 text-center mb-3'>
           <CautionIcon className='pt-3'>
             <Img fluid={data.lavarAsMaos.fluid} alt='Lavar as mãos' />
           </CautionIcon>
           <b>Lave as mãos</b>
           <div className='mt-3'>
             Higienize suas mãos (incluindo dedos, unhas, punho, palma e dorso) com frequência, com água e sabão.
             Reserve o álcool em gel 70% para quando estiver fora de casa.
           </div>
         </div>
         <div className='col-12 col-md-6 col-lg-4 text-center mb-3'>
           <CautionIcon className='pt-3'>
             <Img fluid={data.evitarMaosAoRosto.fluid} alt='Evite levar as mãos ao rosto' />
           </CautionIcon>
           <b>Evite levar as mãos ao rosto</b>
           <div className='mt-3'>
             Ao tossir ou espirrar, cubra o nariz e a boca com o braço ou com um lenço, não com as mãos. E evite
             tocar os olhos, nariz e boca.
           </div>
         </div>
         <div className='col-12 col-md-6 col-lg-4 text-center mb-3'>
           <CautionIcon className='pt-3'>
             <Img fluid={data.oQueTeFazBem.fluid} alt='Faça o que te faz bem' />
           </CautionIcon>
           <b>Faça o que te faz bem</b>
           <div className='mt-3'>
             Com tanta informação chegando de todos os lados, pode ser difícil focar em algo além do vírus. Mas
             tente separar um tempo para o que te traz prazer e para
             <a
               href='https://blog.inter.co/7-atitudes-que-podem-te-fazer-sentir-melhor-na-quarentena/'
               target='_blank'
               rel='noreferrer noopener'
               onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_05',
                  section_name: 'Você já sabe, mas não custa lembrar',
                  element_action: 'click button',
                  element_name: 'cultivar bons hábitos',
                  redirect_url: 'https://blog.inter.co/7-atitudes-que-podem-te-fazer-sentir-melhor-na-quarentena/',
                })
               }}
             >
               {' '}cultivar bons hábitos
             </a>.
           </div>
         </div>
         <div className='col-12 col-md-6 col-lg-4 text-center mb-3'>
           <CautionIcon className='pt-3'>
             <Img fluid={data.sejaSolidario.fluid} alt='Seja solidário, como puder' />
           </CautionIcon>
           <b>Seja solidário, como puder</b>
           <div className='mt-3'>
             Oferecer ajuda, dar preferência ao comércio local e redobrar os cuidados na hora de receber os
             pedidos de delivery são ações que podem
             <a
               href='https://blog.inter.co/5-atitudes-solidarias-para-adotar-na-quarentena/'
               target='_blank'
               rel='noreferrer noopener'
               onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_05',
                  section_name: 'Você já sabe, mas não custa lembrar',
                  element_action: 'click button',
                  element_name: 'fazer a diferença',
                  redirect_url: 'https://blog.inter.co/5-atitudes-solidarias-para-adotar-na-quarentena/',
                })
               }}
             >
               {' '}fazer a diferença
             </a>.
           </div>
         </div>
         <div className='col-12 col-md-6 col-lg-4 text-center mb-3'>
           <CautionIcon className='pt-3'>
             <Img fluid={data.segComprasOnline.fluid} alt='Mantenha a segurança nas compras online' />
           </CautionIcon>
           <b>Mantenha a segurança nas compras online</b>
           <div className='mt-3'>
             Não informe sua senha nas compras virtuais e desconfie de e-mails suspeitos. Confira mais dicas no
             <a
               href='https://blog.inter.co/como-fazer-compras-online-dicas-de-seguranca/'
               target='_blank'
               rel='noreferrer noopener'
               onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_05',
                  section_name: 'Você já sabe, mas não custa lembrar',
                  element_action: 'click button',
                  element_name: 'nosso blog',
                  redirect_url: 'https://blog.inter.co/como-fazer-compras-online-dicas-de-seguranca/',
                })
               }}
             >
               {' '}nosso blog
             </a>, pra aproveitar a comodidade sem dor de
             cabeça.
           </div>
         </div>
       </div>
     </div>
   </section>
 )
}

export default NaoCustaLembrar
