import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const MeasuresIcon = styled.div`
  width: 60px;
  margin-right: 15px;

  @media ${device.desktopLG} {
    margin-right: 25px;
    margin-left: 10px; 
  }
`
export const Spacer = styled.div`
  box-sizing: border-box;
  height: 0;
  margin: 16px 0;
  width: 55px;
  border-bottom: 4px solid #adb5bd;
`
export const CallCenterText = styled.div`
  max-width: 100%;
  @media ${device.desktopLG} {
    max-width: 60%;
  }
`
