import React from 'react'
// Component
import Faq from 'src/components/Faq'
import ViewMore from 'src/components/ViewMore'
// JSON
import pageContext from './../../pageContext.json'
// Style
import { FAQSection } from './style'

const Heigth = 785

const FaqComponent = () => {
  return (
    <FAQSection className='pt-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-8 offset-md-2 col-xl-6 offset-xl-3 text-md-center mb-4'>
            <h3 className='fs-lg-42'>Perguntas frequentes</h3>
            <p>Ainda tem dúvidas? Veja se podemos te ajudar respondendo algumas perguntas ou entre em contato com a
              gente.
            </p>
          </div>
          <ViewMore classwrapper='d-md-flex w-100 flex-wrap' color='gray' height={Heigth}>
            <Faq id='parceria' className='px-0' answerData={pageContext.structuredData.faq} />
          </ViewMore>
        </div>
      </div>
    </FAQSection>
  )
}

export default FaqComponent
