import React from 'react'
import Img from 'gatsby-image'
import Icon from 'src/components/UI/MarkdownIcon'

import { ContentHeader } from './style'
import usePageQuery from './../../pageQuery'

import ScrollTo from 'src/components/ScrollTo'

const Header = () => {
  const data = usePageQuery()
    return (
      <ContentHeader className='pb-5 d-md-flex position-relative align-items-md-center'>
        <div className='container'>
          <div className='row align-items-center pl-3 pt-5 pl-md-2 pt-lg-0'>
            <div className='col-11 col-md-7 col-lg-5 col-xl-6 offset-md-1 offset-lg-6 pl-lg-0 py-5'>
              <Img fluid={data.headerParceria.fluid} alt='Isso é parceria' className='mt-5' />
            </div>
            <div className='col-12 col-md-11 col-lg-6 offset-md-1 offset-lg-6 pr-lg-2 pl-lg-0'>
              <div className='fs-18 pt-3'>
                <h1 className='fs-22 lh-30 mb-3 mr-4'>Unidos, tudo fica mais fácil</h1>
                <p className='mb-3 mr-4 mr-lg-0'>Nesse tempo de tantas dúvidas, nossa parceria com você continua sendo uma certeza.</p>
                <p className='mr-4 mr-lg-0 mb-0'>Colocamos <b>6 medidas iniciais</b> em prática para ajudar a minimizar o impacto do coronavírus na vida de quem conta com a gente.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='d-none d-lg-block text-center'>
          <ScrollTo
            to='#cada-um-tem-ajudado-como-pode'
            styles='scroll-link text-center mt-5'
            section='dobra_01'
            sectionName='Unidos, tudo fica mais fácil'
            elementName='Saiba Mais'
          >
            <span className='fs-16 lh-19 fw-700 text-white m-0 d-block'>
              Saiba Mais
            </span>
            <Icon
              width='24' height='24' icon='navigation/chevron-down' directory='v2' color='white'
            />
          </ScrollTo>
        </div>
      </ContentHeader>
    )
}

export default Header
