import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      bannerDesktop: imageSharp(fluid: {originalName: { regex: "/parceria-banner-lg-2/" }}) {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      bannerMobile: imageSharp(fluid: {originalName: { regex: "/parceria-banner-xs/" }}) {
        fluid(maxWidth: 480, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      headerParceria: imageSharp(fluid: {originalName: { regex: "/isso-e-parceria/" }}) {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      parceriaFamilia: imageSharp(fluid: {originalName: { regex: "/parceria-familia-22/" }}) {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      evitarContatoFisico: imageSharp(fluid: {originalName: { regex: "/evitar-contato-fisico/" }}) {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      lavarAsMaos: imageSharp(fluid: {originalName: { regex: "/lavar-as-maos/" }}) {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      evitarMaosAoRosto: imageSharp(fluid: {originalName: { regex: "/evitar-maos-ao-rosto/" }}) {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      oQueTeFazBem: imageSharp(fluid: {originalName: { regex: "/oq-te-faz-bem/" }}) {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      sejaSolidario: imageSharp(fluid: {originalName: { regex: "/seja-solidario/" }}) {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      segComprasOnline: imageSharp(fluid: {originalName: { regex: "/seguranca-compras-online/" }}) {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
      parceriaCoracoes: imageSharp(fluid: {originalName: { regex: "/parceria-coracoes/" }}) {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
