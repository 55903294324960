import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import consignadoPrivadoParceiroMobile from './../../assets/images/parceria-banner-xs.png'
import consignadoPrivadoParceiroDesktop from './../../assets/images/parceria-banner-lg-2.png'

export const ContentHeader = styled.section`
  align-items: flex-end;
  min-height: calc(100vh - 64px);
  background-image: url(${consignadoPrivadoParceiroMobile});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @media ${device.tablet} {
    align-items: center;
    background-image: url(${consignadoPrivadoParceiroDesktop});
    background-repeat: no-repeat;
  }
  @media ${device.desktopLG} {
    min-height: 100vh;
  }

  p, h1 {
      color: #c5c4c5;
  }
  
  .gatsby-image-wrapper {
    @media ${device.desktopXL} {
      width: 70%;
    }
  }

  .scroll-link {
    bottom: 20px;
    left: 50%;
    line-height: 1.67;
    transform: translateX(-50%);
    width: 100%;
    position: absolute;
  }
`
