import React from 'react'
import Icon from 'src/components/UI/MarkdownIcon'

import { MeasuresIcon, Spacer, CallCenterText } from './style'

const NovasMedidas = () => {
 return (
   <section id='novas-medidas-para-um-novo-tempo' className='py-5'>
     <div className='container'>
       <h3 className='fs-40 text-center'>Novas medidas para um novo tempo</h3>
       <div className='text-center mb-5'>Aqui no Inter, nossa parceria e transparência com você também significarão:</div>
       <div className='row'>
         <div className='col-12 col-lg-6 mb-lg-5 py-4 d-flex flex-row'>
           <MeasuresIcon>
             <Icon directory='v2/products/credito' icon='agendado' width='60' height='60' />
           </MeasuresIcon>
           <div>
             <b>Maior prazo de pagamento</b>
             <div className='fs-15 lh-22 mt-2'>
               Durante esse período, aumentaremos o prazo para pagamento das faturas de cartão de crédito dos
               clientes com limite de até 4 mil reais e que estiverem em dia com as faturas vencidas até
               04/04/2020. Serão até 60 dias para pagar, sem cobrança de juros e outras taxas.
             </div>
             <Spacer />
             <div className='fs-14 lh-20'>
               <b>Entenda o porquê:</b>
               <div className='mt-1'>
                 Para oferecer apoio aos clientes de faixas mais fragilizadas, que podem precisar de um
                 fôlego financeiro pelos próximos dias, o prazo de pagamento de fatura estendido foi
                 designado a quem tem limite de crédito de até 4 mil reais, incluindo contas PJ e MEI.
               </div>
             </div>
           </div>
         </div>
         <div className='col-12 col-lg-6 mb-lg-5 py-4 d-flex flex-row'>
           <MeasuresIcon>
             <Icon directory='v2/action' icon='customer-service' width='60' height='60' />
           </MeasuresIcon>
           <div>
             <b>Atendimento exclusivo para idosos e profissionais da saúde</b>
             <div className='fs-15 lh-22 mt-2'>
               Para cuidar dos nossos idosos e dos profissionais de saúde, criaremos canais de atendimento por
               chat e telefone exclusivos para eles, ainda mais simples e rápidos.
             </div>
             <Spacer />
             <div className='fs-14 lh-20'>
               <b>Entenda o porquê:</b>
               <div className='mt-1'>
                 Os profissionais de saúde estão na linha de frente contra o vírus. Caso precisem de
                 atendimento no Inter, vamos ouvi-los primeiro. E nossos idosos cuidaram da gente até aqui. É
                 hora de cuidarmos deles. Os clientes acima de 60 anos também terão o prazo de pagamento de
                 fatura estendido.
               </div>
             </div>
           </div>
         </div>
         <div className='col-12 col-lg-6 mb-lg-5 py-4 d-flex flex-row'>
           <MeasuresIcon>
             <Icon directory='v2/products/banco' icon='cashback' width='60' height='60' />
           </MeasuresIcon>
           <div>
             <b>Cashback em dobro pra você </b>
             <div className='fs-15 lh-22 mt-2'>
               Nesse período, você terá cashback em dobro no nosso Shopping. Conte com o Super App do Inter pra
               atender suas necessidades sem sair de casa, e tenha ainda mais vantagens.
             </div>
             <Spacer />
             <div className='fs-14 lh-20'>
               <b>Entenda o porquê:</b>
               <div className='mt-1'>
                 O momento é de ficarmos em casa. Então, pelos próximos 60 dias, suas compras no Super App
                 terão cashback em dobro. Caso precise de algo pra sua casa, por exemplo, conte com o Inter
                 também nesse período.
               </div>
             </div>
           </div>
         </div>
         <div className='col-12 col-lg-6 mb-lg-5 py-4 d-flex flex-row'>
           <MeasuresIcon>
             <Icon directory='v2/products/investimentos/renda-variavel' icon='maiores-altas' width='60' height='60' />
           </MeasuresIcon>
           <div>
             <b>Mais boletos e TEDs para empresas</b>
             <div className='fs-15 lh-22 mt-2'>
               Nessa hora, toda ajuda é válida. Também dobraremos a quantidade de boletos e TEDs gratuitas para
               os correntistas PJs e MEIs. Nos meses de abril e maio, serão disponibilizados 200 TEDs e boletos
               gratuitos.
             </div>
             <Spacer />
             <div className='fs-14 lh-20'>
               <b>Entenda o porquê:</b>
               <div className='mt-1'>
                 Essas medidas foram preparadas para os próximos 60 dias, o período com previsão de maiores
                 turbulências econômicas. Queremos ajudar a organização financeira de quem mais precisa nesse
                 momento.
               </div>
             </div>
           </div>
         </div>
         <div className='col-12 col-lg-6 mb-lg-5 py-4 d-flex flex-row'>
           <MeasuresIcon>
             <Icon directory='v2/misc' icon='time' width='60' height='60' />
           </MeasuresIcon>
           <div>
             <b>Nosso time é a nossa força</b>
             <div className='fs-15 lh-22 mt-2'>
               Nossos colaboradores também contam com a gente. Pelos próximos 60 dias, nos meses de abril e
               maio, manteremos todos os empregos diretos que geramos.
             </div>
             <Spacer />
             <div className='fs-14 lh-20'>
               <b>Entenda o porquê:</b>
               <div className='mt-1'>
                 O compromisso com o nosso time é um valor inegociável, mesmo diante dos desafios que
                 enfrentamos neste momento. Pensando nisso, para a tranquilidade e bem-estar de nossos
                 colaboradores nesse período, não haverá nenhum desligamento de colaboradores do Inter
                 durante esse tempo.
               </div>
             </div>
           </div>
         </div>
         <div className='col-12 col-lg-6 mb-lg-5 py-4 d-flex flex-row'>
           <MeasuresIcon>
             <Icon directory='v2/facilidades' icon='city' width='60' height='60' />
           </MeasuresIcon>
           <div>
             <b>Antecipação de recebíveis com taxa zero</b>
             <div className='fs-15 lh-22 mt-2'>
               Oferecemos aos nossos correntistas PJ a antecipação da agenda de recebíveis dos próximos 90 dias, com taxa
               zero. Novos e atuais correntistas com CNPJ ativo há mais de um ano poderão aproveitar esta medida.
             </div>
             <Spacer />
             <div className='fs-14 lh-20'>
               <b>Entenda o porquê:</b>
               <div className='mt-1'>
                 Sabemos que muitas empresas podem se beneficiar do fôlego financeiro nesse período. Por isso, ofereceremos
                 essa opção para ajudar os nossos parceiros PJ a resistirem a esse momento no mercado.
               </div>
             </div>
           </div>
         </div>
         <div className='col-12 mb-5 align-self-stretch text-center text-lg-left d-flex align-items-center'>
           <CallCenterText className='mx-auto text-center fs-17 lh-22'>
             No caso de qualquer dúvida, veja as perguntas frequentes e continue contanto com a gente no <b>chat do nosso app</b>, ou em nossa <b>Central de Atendimento</b>.
           </CallCenterText>
         </div>
       </div>
     </div>
   </section>
 )
}

export default NovasMedidas
