import React from 'react'

import { BannerVideo } from './style'

import OpenVideo from 'src/components/OpenVideo'

import playVideo from './../../../../assets/images/shared/play-video.png'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const PlayVideo = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

 return (
   <section id='cada-um-tem-ajudado-como-pode'>
     <OpenVideo
       link='https://www.youtube.com/embed/kGge3K3SF_Y'
       onClick={() => {
        sendDatalayerEvent({
          section: 'dobra_02',
          section_name: 'null',
          element_action: 'click video',
          element_name: 'Assistir vídeo',
          redirect_url: 'https://www.youtube.com/embed/kGge3K3SF_Y',
        })
       }}
     >
       <BannerVideo>
         <img src={playVideo} alt='Assistir vídeo' width='62' height='62' className='mb-1' />
       </BannerVideo>
     </OpenVideo>
   </section>
 )
}

export default PlayVideo
